<template>
  <div id="admin-layout">
    <AdminNavigation />
    <main>
      <slot></slot>
    </main>
  </div>
</template>

<script lang="ts" setup>
import {useHydration} from '@/helpers/useHydration'

useHydration()
</script>

<style lang="postcss" scoped>
#admin-layout {
  display: grid;
  min-height: 100dvh;
  grid-template-columns: 25rem minmax(0, 1fr);
  > main {
    overflow: auto;
    padding-inline: var(--container-inline-padding);
  }
}
</style>
