<script lang="ts" setup>
import {getInitials} from '@/helpers/initials'
import {useAuthStore} from '@/store/useAuthStore'
const store = useAuthStore()
const localePath = useLocalePath()

const isLoggingOut = ref(false)

const logout = async () => {
  try {
    isLoggingOut.value = true
    await store.logout()
    navigateTo({path: localePath('/')})
  } finally {
    isLoggingOut.value = false
  }
}

const isLocationAdmin = computed(() => {
  return store.user?.data?.is_location_admin ?? false
})

const initials = computed(() => getInitials(`${store.user?.data.first_name} ${store.user?.data.last_name}`))
</script>

<template>
  <nav class="container" aria-label="Main">
    <ul>
      <li class="logo">
        <NuxtLink :to="localePath('/')" class="logo">Work<span class="green">Snap</span></NuxtLink>
      </li>
      <li class="user" v-if="store.user">
        <NuxtLink :to="localePath('/')">
          <RoundedImage :src="store.user.data.image" :alt="initials" :show-alt="true" />
          <div>
            <h3>{{ store.user.data.first_name }} {{ store.user.data.last_name }}</h3>
            <small>{{ store.user.data.email }}</small>
          </div>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink :to="localePath('/admin/bookings')"><i class="fas fa-grip-lines"></i>Bookings overview</NuxtLink>
      </li>
      <li v-if="isLocationAdmin">
        <NuxtLink :to="localePath('/admin/spaces')"><i class="fas fa-grip"></i>Spaces overview</NuxtLink>
      </li>
    </ul>
    <button type="button" class="reset logout" @click="logout" :disabled="isLoggingOut ?? undefined">
      <i class="fa-solid fa-arrow-right-from-bracket"></i>
      {{ $t('navigation.logout') }}
    </button>
  </nav>
</template>

<style scoped lang="postcss">
@import 'open-props/media';
nav {
  display: flex;
  flex-direction: column;
  padding-inline: 0;
  border-right: 1px solid var(--border-color);
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  z-index: 1;
  & ul {
    margin-block: 0;
    padding-inline: 0;
    list-style-type: none;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: var(--container-gap);
    & li {
      display: flex;
      & a {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        white-space: nowrap;
        gap: 0.8rem;
        padding: 1rem 2.4rem;
        color: var(--secondary-color);
        text-decoration: none;
        width: 100%;
        transition: 500ms;
        &:focus-visible,
        &:hover {
          background-color: var(--border-color);
        }
        &.router-link-active {
          color: var(--primary-color);
        }
      }
      &.user {
        --avatar-size: 4.8rem;
        font-size: var(--avatar-size);
        & a {
          display: grid;
          grid-template-columns: var(--avatar-size) minmax(0, 1fr);
          > div {
            display: flex;
            flex-direction: column;
            & h3 {
              font-size: 1.4rem;
              margin: 0;
            }
            & small {
              font-size: 1.2rem;
              color: var(--text-color-tertiary);
            }
          }
        }
      }
      & .logo {
        gap: 0;
        font-size: 2.4rem;
        font-weight: normal;
        border: 0;
        color: var(--grey-color);
        & .green {
          color: var(--primary-color);
        }
      }
    }
  }
  & button[type='button'].logout {
    margin-top: auto;
    padding: 2.4rem;
    transition: 500ms;
    &:focus-visible,
    &:hover {
      background-color: var(--border-color);
    }
  }
}
</style>
